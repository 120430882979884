/* eslint-disable no-param-reassign */
/**
 * Show Password
 * This extension allows a user to reveal the password they have typed.
 */

export default () => {
    const passwordInputs = document.querySelectorAll('input[type="password"]');
    if (!passwordInputs.length) return;
    const hideLabel = 'Hide Password.';
    const hideText = 'Hide';
    const showLabel = 'Show password as plain text. Warning: this will display your password on the screen.';
    const showText = 'Show';

    function getPreviousSibling(element, selector) {
        let sibling = element.previousElementSibling;

        if (!selector) {
            return sibling;
        }

        while (sibling) {
            if (sibling.matches(selector)) {
                return sibling;
            }
            sibling = sibling.previousElementSibling;
        }
    }

    passwordInputs.forEach((passwordInput) => {
        const toggleButton = document.createElement('button');
        const findLabel = getPreviousSibling(passwordInput, 'label');

        toggleButton.classList.add('c-button');
        toggleButton.classList.add('u-bg-transparent');
        toggleButton.classList.add('x-toggle-password');
        toggleButton.classList.add('button--clear');
        if (findLabel && findLabel.offsetWidth <= 1) {
            toggleButton.classList.add('x-toggle-password--no-label');
        }
        toggleButton.setAttribute('aria-label', showLabel);
        toggleButton.setAttribute('data-hook', 'toggle-password');
        toggleButton.textContent = showText;
        toggleButton.type = 'button';
        passwordInput.parentElement.style.position = 'relative';
        passwordInput.parentElement.insertBefore(toggleButton, passwordInput.nextSibling);
    });

    const togglePasswordButtons = document.querySelectorAll('[data-hook="toggle-password"]');

    if (togglePasswordButtons.length > 0) {
        togglePasswordButtons.forEach((togglePasswordButton) => {
            togglePasswordButton.addEventListener('click', (e) => {
                const clicked = e.currentTarget;
                const closestInput = getPreviousSibling(clicked, 'input');
                if (closestInput.type === 'password') {
                    closestInput.type = 'text';
                    $(clicked).textContent = hideText;
                    clicked.setAttribute('aria-label', hideLabel);
                } else {
                    closestInput.type = 'password';
                    $(clicked).textContent = showText;
                    clicked.setAttribute('aria-label', showLabel);
                }
            });
        });
    }
};
