export default () => {
    let $activeItem;
    let flag = false;
    $('[data-desktop-open-submenu]').on('click', (e) => {
        const $item = $(e.currentTarget);
        if ($activeItem) {
            $activeItem.parent().next().hide();
            // $activeItem = null;
        }
        if (!$item.is($activeItem) || ($item.parent().next().css('display') && flag)) {
            $activeItem = $item;
            $activeItem.parent().next().show();
            flag = false;
        } else {
            flag = true;
        }
    });
};
