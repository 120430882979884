export function canvasToBlob(canvas: HTMLCanvasElement, fileType?: 'image/png' | 'image/jpeg' | 'image/webp', quality: number = 100): Promise<Blob> {
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob: Blob | null): void => {
            if (!blob) {
                return reject(new Error('Failed to generate Blob'));
            }

            resolve(blob);
        }, fileType, Math.min(100, Math.max(quality, 0)) / 100);
    });
}
