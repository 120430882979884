import Global from './pages/global';
import '../scss/theme.scss';

const pageId: PageClasses = document.body.id.replace('-', '') as PageClasses;

// Page Templates
const pageClasses: { [K in PageClasses]?: null | (() => Promise<any>) } = {
    jsBASK: () => import('./pages/basket'), // Basket
    jsCTGY: () => import('./pages/category'), // Category
    jsCTUS: () => import('./pages/contact'), // Contact
    jsINVC: () => import('./pages/order-confirmation'), // Order confirmation
    jsOCST: () => import('./pages/order-customer'), // Checkout - Customer
    jsOPAY: () => import('./pages/order-payment'), // Checkout - Payment
    jsOSEL: () => import('./pages/order-shipping'), // Checkout - Shipping
    jsCADE: () => import('./pages/customer-address-edit'), // Customer Address Edit
    jsCADA: () => import('./pages/customer-address-add'), // Customer Address Add
    jsACAD: () => import('./pages/customer-create'), // Customer Create
    jsACED: () => import('./pages/customer-edit'), // Customer Edit
    jsORDS: () => import('./pages/order-status'), // Order Status
    jsPLST: () => import('./pages/product-list'), // Product List
    jsPROD: () => import('./pages/product'), // Product
    jsSRCH: () => import('./pages/search'), // Search
    jsSFNT: () => import('./pages/storefront'), // Storefront
};

interface CustomClasses {}

// Alternate Page Templates (for specific categories and products)
const customClasses: CustomClasses = {
    // ex. overwrite with 'CTGY-MODEL': () => ('./theme/category-model'),
};

/**
 * This function gets called on page load with the current template loaded and JS Context
 * @param pageType String
 * @param contextJSON Context to pass from the template
 * @returns {*}
 */
function mapleBootstrap(pageType: PageClasses, contextJSON: Miva.Context | string | null | undefined = null, loadGlobal: boolean = true): { load(): void; } {
    const context: Miva.Context = typeof contextJSON === 'string' ? JSON.parse(contextJSON || '{}') : contextJSON || {};
    context.pageId = pageType;

    return {
        load() {
            $(() => {
                // Load globals
                if (loadGlobal) {
                    Global.load(context);
                }

                const importPromises: Promise<any>[] = [];

                // Find the appropriate page loader based on pageType
                const pageClassImporter: (() => Promise<any>) | null | undefined = pageClasses[pageType];

                if (typeof pageClassImporter === 'function') {
                    importPromises.push(pageClassImporter());
                }

                // See if there is a page class default for a custom template
                const customTemplateImporter: (() => Promise<any>) | null | undefined = customClasses[context.pageCode as keyof CustomClasses] as (() => Promise<any>) | null | undefined;

                if (typeof customTemplateImporter === 'function') {
                    importPromises.push(customTemplateImporter());
                }

                // Wait for imports to resolve, then call load() on them
                Promise.all(importPromises).then(imports => {
                    imports.forEach(imported => {
                        imported.default.load(context);
                    });
                });
            });
        },
    };
}

window.mapleBootstrap = mapleBootstrap;

mapleBootstrap(pageId, window.mapleContext).load();
