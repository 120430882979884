/* Simple off canvas menu */

class OffCanvasMenu {
    constructor() {
        this.initVariables();
    }

    initVariables() {
        this.$openMenuButton = $('[data-open-mobile-menu]');
        this.$closeMenuButton = $('[data-close-mobile-menu]');

        this.$openSubmenuButton = $('[data-open-submenu]');
        this.$closeSubmenuButton = $('[data-close-submenu]');
        this.$thirdMenuToggle = $('[data-toggle-level-three]');

        this.openMobileMenu = this.openMobileMenu.bind(this);
        this.closeMobileMenu = this.closeMobileMenu.bind(this);
        this.openMobileSubmenu = this.toggleMobileSubmenu.bind(this);
        this.closeMobileSubmenu = this.closeMobileSubmenu.bind(this);
        this.toggleMobileThirdMenu = this.toggleMobileThirdMenu.bind(this);

        this.bindEvents();
    }

    bindEvents() {
        this.$openMenuButton.on('click', this.openMobileMenu);
        this.$closeMenuButton.on('click', this.closeMobileMenu);
        this.$openSubmenuButton.on('click', this.toggleMobileSubmenu);
        this.$closeSubmenuButton.on('click', this.closeMobileSubmenu);
        this.$thirdMenuToggle.on('click', this.toggleMobileThirdMenu);

        $('main').on('click', this.closeMobileMenu);
    }

    openMobileMenu() {
        $('body').addClass('mobile-menu-open');
    }

    closeMobileMenu() {
        $('body').removeClass('mobile-menu-open');
    }

    toggleMobileSubmenu(e) {
        const $item = $(e.currentTarget);
        $item.parent('.has-submenu').toggleClass('is-open');
    }

    closeMobileSubmenu(e) {
        const $item = $(e.currentTarget);
        $item.parents('.has-submenu').removeClass('is-open');
    }

    toggleMobileThirdMenu(e) {
        const $item = $(e.currentTarget);
        $item.parent('.mobile-submenu-level-3').toggleClass('is-open');
    }
}

export default () => new OffCanvasMenu();
