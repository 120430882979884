export default class PageManager<TContext extends Miva.Context = Miva.Context> {
    context: TContext;

    // eslint-disable-next-line no-multi-assign
    dataLayer: any[] = window.dataLayer = window.dataLayer || [];

    constructor(context: TContext) {
        this.context = context;
    }

    public type(): string {
        return this.constructor.name;
    }

    public onReady(): void {
    }

    static load<TContext extends Miva.Context = Miva.Context>(context: TContext): PageManager<TContext> {
        const page: PageManager<TContext> = new this(context);

        $((): void => page.onReady.bind(page)());

        return page;
    }
}
