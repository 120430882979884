import '@selectize/selectize';

export default () => {
    $<HTMLSelectElement>('[data-searchable]').each((_i: number, elem: HTMLSelectElement): void => {
        // let oldValue: string | null = null;

        $(elem).selectize({
            highlight: true,
            create: false,
            // onDropdownOpen(this: Selectize.IApi<any, any>, _dropdown: JQuery<HTMLDivElement>): void {
            //     // set current value and clear selected field for now when dropdown is opened
            //     oldValue = this.getValue();
            //     this.clear();
            // },
            // onDropdownClose(this: Selectize.IApi<any, any>, dropdown: JQuery<HTMLDivElement>): void {
            //     // if no value is set, and the search input is not focused, restore the old value
            //     if (!this.getValue() && !dropdown.siblings('.selectize-input').find('input').is(':focus')) {
            //         this.setValue(oldValue);
            //     }
            // },
        });
    });
};
