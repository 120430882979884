export default () => {
    /**
     * Add `ID`, `CLASS`, and `aria-describedby` attributes to `INPUT` and `SELECT` elements
     * dynamically created by Miva, where needed.
     */
    const mvtInputWraps = document.querySelectorAll('[data-hook~="mvt-input"]');
    const mvtSelectWraps = document.querySelectorAll('[data-hook~="mvt-select"]');
    const styleMVT = function styleMVT(element, input) {
        const mvtItems = element.querySelectorAll(input);
        const attr = {
            id: element.getAttribute('data-mvt-id'),
            classes: element.getAttribute('data-mvt-classlist'),
            description: element.getAttribute('data-mvt-describedby'),
            autocomplete: element.getAttribute('data-mvt-autocomplete'),
        };

        mvtItems.forEach((mvtItem) => {
            if (attr.id !== null) {
                mvtItem.setAttribute('id', attr.id);
            }
            if (attr.classes !== null) {
                mvtItem.setAttribute('class', attr.classes);

                if (attr.classes.includes('c-form-select__dropdown')) {
                    $(mvtItem).wrap('<div class="c-form-select"></div>');
                }
            }
            if (attr.description !== null) {
                mvtItem.setAttribute('aria-describedby', attr.description);
            }
            if (attr.autocomplete !== null) {
                mvtItem.setAttribute('autocomplete', attr.autocomplete);
            }
        });
    };

    mvtInputWraps.forEach((element) => {
        styleMVT(element, 'input');
    });

    mvtSelectWraps.forEach((element) => {
        styleMVT(element, 'select');
    });
};
