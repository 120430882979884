export default () => {
    const $breadcrumbNavigation = $.hook('collapsing-breadcrumbs');
    const $triggerArea = $.hook('collapsing-breadcrumbs__trigger-area');
    const $displayButton = $.hook('collapsing-breadcrumbs__button');
    const $visibleLinks = $.hook('collapsing-breadcrumbs__list');
    const $hiddenLinks = $.hook('collapsing-breadcrumbs__group');
    let numOfItems = 0;
    let totalSpace = 64;
    const breakWidths = [];
    let availableSpace;
    let numOfVisibleItems;
    let requiredSpace;

    /**
    * Check the total space required for the breadcrumb links and how many there are.
    */
    $visibleLinks.children().each((e) => {
        totalSpace += $(e.currentTarget).outerWidth(true) + (parseInt($visibleLinks.css('padding-left'), 10));
        numOfItems += 1;
        breakWidths.push(totalSpace);
    });

    /**
     * Compare the needed space with the available space and move breadcrumbs accordingly.
     * @returns {jQuery}
     */
    $.fn.checkNavigationOverflow = (e) => {
        // Get instant state
        availableSpace = $visibleLinks.outerWidth(true) - parseInt($visibleLinks.css('padding-right'), 10);
        numOfVisibleItems = $visibleLinks.children().length;
        requiredSpace = breakWidths[numOfVisibleItems - 1];

        // There is not enough space
        if (requiredSpace > availableSpace) {
            $visibleLinks.children('[data-hook="collapsing-breadcrumbs__item"]').first().appendTo($hiddenLinks);
            numOfVisibleItems -= 1;
            $breadcrumbNavigation.addClass('is-loaded');
            $(e.currentTarget).checkNavigationOverflow();
        } else if (availableSpace > breakWidths[numOfVisibleItems]) {
            // There is more than enough space
            $hiddenLinks.children().last().prependTo($visibleLinks).insertAfter($triggerArea);
            numOfVisibleItems += 1;
            $breadcrumbNavigation.addClass('is-loaded');
            this.checkNavigationOverflow();
        }

        // Update the button accordingly
        if (numOfVisibleItems === numOfItems) {
            $triggerArea.addClass('u-hidden');
        } else {
            $triggerArea.removeClass('u-hidden');
        }

        return this;
    };

    /**
     * Check for rezise and control button click.
     */
    $.fn.collapsingBreadcrumbs = function collapsingBreadcrumbs() {
        const initElement = this;
        let animationTimeout;

        window.addEventListener('resize', () => {
            if (animationTimeout) {
                window.cancelAnimationFrame(animationTimeout);
            }

            animationTimeout = window.requestAnimationFrame(() => {
                initElement.checkNavigationOverflow();
            });
        }, false);

        $displayButton.on('click', () => {
            $hiddenLinks.toggleClass('u-hidden');
        });

        initElement.checkNavigationOverflow();
    };

    /**
     * Initialize the extension.
     */
    if ($breadcrumbNavigation.length > 0) {
        $breadcrumbNavigation.collapsingBreadcrumbs();
    }
};
