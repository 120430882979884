import Konva from 'konva';
import { v4 as uuidv4 } from 'uuid';
import LoggingService from './logger';

import { canvasToBlob } from './utils/canvasToBlob';

const { CDN_ORIGIN = '' } = process.env || {};

interface AjaxAddToCartMethods {
    init: () => void;
}

const logger: LoggingService = new LoggingService('AJAX Add to Cart');

export default (): AjaxAddToCartMethods => {
/**
 * When called from a `theme.js` file on a product page, this extension will
 * work with the default page code to add a product to the cart utilizing an
 * AJAX call to the form processor.
 *
 * The function contains internal error checking as well as a check to see which
 * page was reached and displaying messages accordingly. If the store is also
 * utilizing the `mini-basket` extension, said extension will be triggered for
 * display upon successfully adding a product to the cart.
 */

    const publicMethods: Partial<AjaxAddToCartMethods> = {}; // Placeholder for public methods

    /**
     * Initialize the plugin
     * @public
     */
    publicMethods.init = (): void => {
        const purchaseButton: HTMLInputElement | null = document.querySelector<HTMLInputElement>('[data-hook="add-to-cart"]');

        if (!document.body.contains(purchaseButton) || !purchaseButton) {
            return;
        }

        const purchaseButtonText: string | null = (purchaseButton.nodeName.toLowerCase() === 'input') ? purchaseButton.value : purchaseButton.textContent;
        const purchaseForm: HTMLFormElement | null = document.querySelector<HTMLFormElement>('[data-hook="purchase"]');

        if (!purchaseForm) {
            return;
        }

        const purchaseFormActionInput: HTMLInputElement | null = purchaseForm.querySelector<HTMLInputElement>('input[name="Action"]');
        const responseMessage: HTMLDivElement | null = document.querySelector<HTMLDivElement>('[data-hook="purchase-message"]');
        const miniBasketCount: NodeListOf<HTMLSpanElement> = document.querySelectorAll<HTMLSpanElement>('[data-hook~="mini-basket-count"]');
        const miniBasketAmount: NodeListOf<HTMLSpanElement> = document.querySelectorAll<HTMLSpanElement>('[data-hook~="mini-basket-amount"]');
        const requiredFields: NodeListOf<HTMLFormFieldElement> = purchaseForm.querySelectorAll<HTMLFormFieldElement>('[required]');

        purchaseButton.addEventListener('click', async (evt: MouseEvent): Promise<void> => {
            if (purchaseFormActionInput?.value !== 'ADPR' && purchaseFormActionInput?.value !== 'ADPM') {
                return;
            }

            evt.preventDefault();
            evt.stopImmediatePropagation();

            const action: string | null = purchaseButton.getAttribute('data-action');

            if (!action) {
                return;
            }

            purchaseForm.action = action;
            // purchaseFormActionInput.value = 'ADPR';

            const data: FormData = new FormData(purchaseForm);
            const request: XMLHttpRequest = new XMLHttpRequest(); // Set up our HTTP request

            purchaseForm.setAttribute('data-status', 'idle');

            for (const field of requiredFields) {
                field.setCustomValidity('');

                if (!field.validity.valid) {
                    if (field.type === 'checkbox') {
                        field.focus();
                        field.setCustomValidity('Please check this box if you want to proceed.');
                        field.reportValidity();
                        purchaseForm.setAttribute('data-status', 'submitting');
                        break;
                    } else if (field.type === 'radio') {
                        field.focus();
                        field.setCustomValidity('Please select one of these options.');
                        field.reportValidity();
                        purchaseForm.setAttribute('data-status', 'submitting');
                        break;
                    } else if (field.type.indexOf('select') !== -1 || field.parentElement && field.parentElement.classList.contains('selectize-input')) {
                        field.focus();
                        field.setCustomValidity('Please select an item in the list.');
                        field.reportValidity();
                        purchaseForm.setAttribute('data-status', 'submitting');
                        if (field.parentElement?.classList.contains('!hidden')) {
                            $(field).parents('.x-product-layout-purchase__options-attribute--swatches').find('.error-message').html('<p>Please select one of these options.</p>');
                            field.parentElement.parentElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            $('.error-message p').delay(4000).fadeOut(300);
                        }
                        break;
                    } else if (field.type === 'text' || field.type === 'textarea') {
                        field.focus();
                        field.setCustomValidity('Please fill out this field.');
                        field.reportValidity();
                        purchaseForm.setAttribute('data-status', 'submitting');
                        break;
                    }
                }
            }

            if (purchaseForm.getAttribute('data-status') !== 'submitting') {
                purchaseForm.setAttribute('data-status', 'submitting');
                purchaseButton.classList.add('is-disabled');

                if (purchaseButton.nodeName.toLowerCase() === 'input') {
                    purchaseButton.value = 'Processing...';
                } else {
                    purchaseButton.textContent = 'Processing...';
                }

                if (window.Konva) {
                    const stage: Konva.Stage | undefined = Konva.stages.find((item: Konva.Stage): boolean => $(item.content).closest('.x-product-layout-images').length > 0);

                    if (stage) {
                        let image: Blob | undefined;

                        try {
                            image = await canvasToBlob(stage.toCanvas());

                            const formData: FormData = new FormData();
                            const uuid: string = uuidv4();
                            const fileName: string = `${uuid}.png`;

                            formData.append('file', image, fileName);

                            const url: URL = new URL(CDN_ORIGIN);

                            const response: CDN.Response = await $.ajax({
                                url: `${url.origin}/upload`,
                                type: 'PUT',
                                data: formData,
                                contentType: false,
                                processData: false,
                            });

                            const { files: [file] } = response;

                            if (file) {
                                const path: string = `${url.origin}/${file.filename}`;
                                const $builderImageField = $<HTMLInputElement>('[data-attribute="builder-image"]', purchaseForm);

                                if ($builderImageField.length) {
                                    const name: string | undefined = $builderImageField.prop('name');

                                    if (name) {
                                        data.set(name, path);
                                    }
                                }
                            }
                        } catch (error) {
                            logger.error(error);
                        }
                    }
                }

                if (responseMessage) {
                    responseMessage.innerHTML = '';
                }

                // if (data.has('product-builder-confirmation')) {
                //     data.delete('product-builder-confirmation');
                // }

                // Setup our listener to process completed requests
                request.onreadystatechange = () => {
                    // Only run if the request is complete
                    if (request.readyState !== 4) {
                        return;
                    }

                    // Process our return data
                    if (request.status === 200) {
                        // What do when the request is successful
                        const response: Document = request.response;

                        if (response.body.id === 'js-BASK') {
                            const basketData: HTMLElement | null = response.querySelector('[data-hook="mini-basket"]');

                            if (basketData) {
                                const basketCount: string | null = basketData.getAttribute('data-item-count');
                                const basketSubtotal: string | null = basketData.getAttribute('data-subtotal');

                                if (miniBasketCount) {
                                    for (let mbcID = 0; mbcID < miniBasketCount.length; mbcID++) {
                                        miniBasketCount[mbcID].textContent = basketCount; // Update mini-basket quantity (display only)
                                    }
                                }

                                if (miniBasketAmount) {
                                    for (let mbaID = 0; mbaID < miniBasketAmount.length; mbaID++) {
                                        miniBasketAmount[mbaID].textContent = basketSubtotal; // Update mini-basket subtotal (display only)
                                    }
                                }
                            }

                            const miniBasket: HTMLElement | null = document.querySelector<HTMLElement>('[data-hook="mini-basket"]');

                            if (miniBasket) {
                                const responseMiniBasket: HTMLElement | null = response.querySelector<HTMLElement>('[data-hook="mini-basket"]');

                                if (responseMiniBasket) {
                                    miniBasket.innerHTML = responseMiniBasket.innerHTML;
                                }
                            }

                            setTimeout(() => {
                                document.querySelector<HTMLElement>('[data-hook="open-mini-basket"]')?.click();
                            }, 100);

                            // Re-Initialize Attribute Machine (if it is active)
                            if (typeof window.attrMachCall !== 'undefined') {
                                // eslint-disable-next-line no-undef
                                window.attrMachCall.Initialize();
                            }
                        } else if (response.body.id === 'js-PATR' && responseMessage) {
                            const findRequired: NodeListOf<HTMLLabelElement> = purchaseForm.querySelectorAll<HTMLLabelElement>('.is-required');
                            const missingAttributes: string[] = [];

                            for (let id: number = 0; id < findRequired.length; id++) {
                                missingAttributes.push(` ${findRequired[id].title}`);
                            }

                            responseMessage.innerHTML = `<div class="x-messages x-messages--warning">All <em class="u-color-red">Required</em> options have not been selected.<br />Please review the following options: <span class="u-color-red">${missingAttributes}</span>.</div>`;
                        } else if (response.body.id === 'js-PLMT' && responseMessage) {
                            responseMessage.innerHTML = '<div class="x-messages x-messages--warning">We do not have enough of the combination you have selected.<br />Please adjust your quantity.</div>';
                        } else if (response.body.id === 'js-POUT' && responseMessage) {
                            responseMessage.innerHTML = '<div class="x-messages x-messages--warning">The combination you have selected is out of stock.<br />Please review your options or check back later.</div>';
                        } else if (responseMessage) {
                            responseMessage.innerHTML = '<div class="x-messages x-messages--warning">Please review your selection.</div>';
                        }

                        // Reset button text and form status
                        purchaseButton.classList.remove('is-disabled');

                        if (purchaseButtonText) {
                            if (purchaseButton.nodeName.toLowerCase() === 'input') {
                                purchaseButton.value = purchaseButtonText;
                            } else {
                                purchaseButton.textContent = purchaseButtonText;
                            }
                        }

                        purchaseForm.setAttribute('data-status', 'idle');
                    } else {
                        // What do when the request fails
                        logger.log('The request failed!');
                        purchaseForm.setAttribute('data-status', 'idle');
                    }
                };

                /**
                 * Create and send a request
                 * The first argument is the post type (GET, POST, PUT, DELETE, etc.)
                 * The second argument is the endpoint URL
                 */
                request.open(purchaseForm.method, purchaseForm.action, true);
                request.responseType = 'document';
                request.send(data);
            }
        }, false);
    };

    /**
     * Public APIs
     */
    return publicMethods as AjaxAddToCartMethods;
};
