import LoggingService from '../common/logger';

const logger = new LoggingService('Product Card');

export default () => {
    const $productCard = $('[data-product-card]');

    if (!$productCard) return;

    function swatches() {
        const $swatch = $productCard.find('[data-swatch]');

        if (!$swatch) return;

        $swatch.on('click', (e) => {
            const $currentTarget = $(e.currentTarget);
            const attributeID = $currentTarget.data('id');
            const select = document.getElementById(attributeID);
            $currentTarget.parents('.card').find('[data-swatch]').removeClass('selected');
            $currentTarget.addClass('selected');

            // Trigger variant_changed event
            select.value = $currentTarget.val();
            select.dispatchEvent(new Event('change'));
        });

        // Show the default option images on page load
        $productCard.find('.swatch .selected').trigger('click');

        // eslint-disable-next-line no-undef
        if (!window.MivaEvents || typeof window.MivaEvents === 'undefined') return;

        // eslint-disable-next-line no-undef
        MivaEvents.SubscribeToEvent('variant_changed', async (d) => {
            const url = 'json.mvc?Store_Code=blueridgemountaingifts&Function=Runtime_ProductImageList_Load_Product_Variant';

            const data = {
                Product_Code: d.product_code,
                Variant_ID: d.variant_id,
                Image_Sizes: [
                    '300x300',
                    '80x80:MultipleOnly',
                    '960x960',
                ].map((size) => encodeURIComponent(size)).join('|'),
                Session_type: 'runtime',
            };

            // Get the variant images
            try {
                const response = await $.ajax({
                    url,
                    type: 'POST',
                    data,
                    dataType: 'json',
                });

                if (!response) return;

                const mainImage = response.data.find(img => img.type_code === 'main');

                if (!mainImage || !mainImage.image_data || mainImage.image_data.length === 0) return;

                const newSrc = mainImage.image_data[0];

                $(`[data-product-image-code="${d.product_code}"]`).attr('src', newSrc);
            } catch (error) {
                logger.error(error);
            }
        });
    }

    swatches();
};
