import { merge } from 'lodash';
import 'slick-carousel';
import Swiper, {
    A11y,
    Keyboard,
    Lazy,
    Navigation,
    Pagination,
    SwiperOptions,
} from 'swiper';

// import { breakpoints } from '../common/utils/tailwind';

export default () => {
    const $slick = $('[data-slick]');
    const $swiper = $('[data-swiper]');

    if ($slick.length) {
        $slick.slick();
    }

    if ($swiper.length) {
        $swiper.each((_i: number, element: HTMLElement): void => {
            let options: SwiperOptions | string | undefined = $(element).data('swiper');

            if (!options || typeof options === 'string') {
                options = {};
            }

            const defaults: SwiperOptions = {
                slidesPerView: 1,
                spaceBetween: 24,
                centerInsufficientSlides: true,
                lazy: {
                    enabled: true,
                    checkInView: true,
                    loadPrevNext: true,
                    loadPrevNextAmount: 4,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                },
                modules: [
                    A11y,
                    Keyboard,
                    Lazy,
                    Navigation,
                    Pagination,
                ],
                // breakpoints: {
                //     [breakpoints.sm]: {
                //         slidesPerView: 2,
                //     },
                //     [breakpoints.md]: {
                //         slidesPerView: 3,
                //     },
                //     [breakpoints.lg]: {
                //         slidesPerView: 4,
                //     },
                // },
            };

            // eslint-disable-next-line no-new
            new Swiper(element, merge(defaults, options));
        });
    }
};
