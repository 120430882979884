export default () => {
    const adjusters = document.querySelectorAll('[data-hook="quantify"]');

    function groupSubmit(event, quantity) {
        if (event.key !== 8 && event.key !== 37 && event.key !== 38 && event.key !== 39 && event.key !== 40 && event.key !== 46 && quantity.value !== '') {
            document.querySelector(`[data-hook="${event.target.getAttribute('data-group')}"]`).submit();
        }
    }

    function allowRemoveUpdate() {
        const quantities = document.querySelectorAll('[data-hook="group-quantity"]');

        function toggleRemove(row, qty) {
            const removeToggle = row.previousElementSibling;
            const groupForm = document.querySelector(`[data-hook="${row.getAttribute('data-group')}"]`);

            if (removeToggle.dataset.hook !== 'remove') {
                if (qty > '1') {
                    if (groupForm) {
                        groupForm.elements.Action.value = 'QTYG';
                    }
                    // removeToggle.firstElementChild.classList.remove('u-icon-remove');
                    // removeToggle.firstElementChild.classList.add('u-icon-subtract');
                    removeToggle.setAttribute('data-action', 'decrement');
                } else if (qty === '1') {
                    if (groupForm) {
                        groupForm.elements.Action.value = 'QTYG';
                    }
                    // removeToggle.firstElementChild.classList.remove('u-icon-subtract');
                    // removeToggle.firstElementChild.classList.add('u-icon-remove');
                    removeToggle.setAttribute('data-action', 'remove');
                } else {
                    if (groupForm) {
                        groupForm.elements.Action.value = 'RGRP';
                    }
                    // removeToggle.firstElementChild.classList.remove('u-icon-subtract');
                    // removeToggle.firstElementChild.classList.add('u-icon-remove');
                    removeToggle.setAttribute('data-action', 'remove');
                }
            }
        }

        if (quantities) {
            for (let id = 0; id < quantities.length; id++) {
                const quantityLine = quantities[id];
                let updateTimeout = null;

                toggleRemove(quantityLine, quantityLine.value);

                quantityLine.addEventListener('change', (event) => {
                    const input = event.currentTarget;

                    clearTimeout(updateTimeout);
                    updateTimeout = setTimeout(() => {
                        toggleRemove(input, input.value);
                        groupSubmit(event, input);
                    }, 500);
                });

                quantityLine.addEventListener('input', (event) => {
                    const input = this;

                    clearTimeout(updateTimeout);
                    updateTimeout = setTimeout(() => {
                        toggleRemove(input, input.value);
                        groupSubmit(event, input);
                    }, 500);
                });
            }
        }
    }

    for (let id = 0; id < adjusters.length; id++) {
        adjusters[id].addEventListener('keydown', (keyEvent) => {
            if (keyEvent.target.closest('input')) {
                if (keyEvent.key === 'Enter') {
                    keyEvent.preventDefault();
                }
            }
        });

        adjusters[id].addEventListener('click', (event) => {
            if (event.target.closest('button')) {
                const button = event.target;
                const inputs = [].filter.call(event.currentTarget.children, (sibling) => sibling !== button && sibling.closest('input'));
                const input = inputs[0];
                let value = parseInt(input.value, 10);
                const action = button.getAttribute('data-action');
                const changed = document.createEvent('HTMLEvents');
                const groupForm = document.querySelector(`[data-hook="${input.getAttribute('data-group')}"]`);

                changed.initEvent('change', true, false);
                event.stopPropagation();
                event.preventDefault();

                if (action === 'decrement') {
                    value = value > 1 ? value - 1 : 1;

                    input.value = value;
                    input.dispatchEvent(changed);
                    allowRemoveUpdate();
                } else if (action === 'increment') {
                    value += 1;

                    input.value = value;
                    if (groupForm) {
                        groupForm.elements.Action.value = 'QTYG';
                    }
                    input.dispatchEvent(changed);
                    allowRemoveUpdate();
                } else {
                    input.value = 0;
                    input.dispatchEvent(changed);
                }
            }
        });
    }

    allowRemoveUpdate();
};
