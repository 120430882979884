import PageManager from './page-manager';

// Global JS
import collapsingBreadcrumbs from '../global/collapsing-breadcrumbs';
import miniBasket from '../global/mini-basket';
import addToCart from '../common/ajax-add-to-cart';
import utilities from '../global/utilities';
import dialog from '../global/dialog';
import dynamicMivaFields from '../global/dynamic-miva-fields';
import mobileMenu from '../global/mobile-menu';
import desktopMenu from '../global/desktop-menu';
import carousel from '../global/carousel';
import showPassword from '../common/show-password';

// Common JS
import quantify from '../common/quantify';
import a11yToggle from '../common/a11y-toggle';
import a11yTabs from '../common/a11y-tabs';

import selectize from '../global/selectize';
import productCard from '../global/product-card';

export default class Global extends PageManager {
    onReady() {
        dynamicMivaFields();
        mobileMenu();
        desktopMenu();
        dialog();
        a11yToggle();
        a11yTabs();
        utilities();
        collapsingBreadcrumbs();
        this.minibasket = miniBasket();
        this.minibasket.init();
        addToCart(this.minibasket);
        quantify();
        carousel();
        showPassword();
        selectize();
        productCard();
    }
}
