import Konva from 'konva/lib/Core';
import { Util as KonvaUtil } from 'konva/lib/Util';
import { cloneDeep } from 'lodash';
import pino, { BaseLogger, Logger, LoggerOptions } from 'pino';

const { LOG_LEVEL = 'DEBUG', ENVIRONMENT } = process.env;

const START_INDEX: number = 0;
const INCREMENT_COUNT: number = 1;
const DEFAULT_DEPTH: number = 2;

if (LOG_LEVEL === 'ERROR' || LOG_LEVEL === 'FATAL' || LOG_LEVEL === 'SILENT') {
    Konva.showWarnings = false;
}

function generateRedactionPaths(paths: string[], depth: number = DEFAULT_DEPTH): string[] {
    return paths.reduce((acc: string[], path: string): string[] => {
        let currentPath: string = path;
        let i: number = START_INDEX;

        acc.push(currentPath, `${currentPath}.*`);

        while (i < depth) {
            currentPath = `*.${currentPath}`;
            acc.push(currentPath, `${currentPath}.S`);
            i += INCREMENT_COUNT;
        }

        return acc;
    }, []);
}

const redactionFields: string[] = [
    'accessToken',
    'access_token',
    'clientSecret',
    'client_secret',
    'password',
    'token',
];

export const config: LoggerOptions = {
    level: LOG_LEVEL.toLowerCase(),
    base: {
        env: ENVIRONMENT,
    },
    redact: generateRedactionPaths(redactionFields),
    // browser: {
    //     asObject: true,
    // },
    timestamp(): string {
        // override type since date is actually supported
        return new Date() as unknown as string;
    },
};

const logger: Logger = pino(config);

const KonvaLogger: Logger = logger.child({ name: 'Konva' });
const KONVA_WARNING: string = 'Konva warning: ';
const KONVA_ERROR: string = 'Konva error: ';

KonvaUtil.warn = (msg: string): void => { if (Konva.showWarnings) KonvaLogger.warn(`${KONVA_WARNING}${msg}`); };
KonvaUtil.error = (msg: string): void => KonvaLogger.error(`${KONVA_ERROR}${msg}`);

// override console.log
// console.log = (obj: unknown, msg?: string, ...args: any[]) => logger.debug(cloneDeep(obj), cloneDeep(msg), ...args.map(arg => cloneDeep(arg)));
// console.debug = (obj: unknown, msg?: string, ...args: any[]) => logger.debug(cloneDeep(obj), cloneDeep(msg), ...args.map(arg => cloneDeep(arg)));
// console.info = (obj: unknown, msg?: string, ...args: any[]) => logger.info(cloneDeep(obj), cloneDeep(msg), ...args.map(arg => cloneDeep(arg)));
// console.warn = (obj: unknown, msg?: string, ...args: any[]) => logger.warn(cloneDeep(obj), cloneDeep(msg), ...args.map(arg => cloneDeep(arg)));
// console.error = (obj: unknown, msg?: string, ...args: any[]) => logger.error(cloneDeep(obj), cloneDeep(msg), ...args.map(arg => cloneDeep(arg)));

export class LoggingService implements BaseLogger {
    private logger: Logger;

    public get level(): string {
        return this.logger.level;
    }

    public set level(level: string) {
        this.logger.level = level;
    }

    public constructor(name: string) {
        this.logger = logger.child({ name });
    }

    public log(msg: string, ...args: any[]): void;
    public log(obj: unknown, msg?: string, ...args: any[]): void;
    public log<T extends object>(obj: T, msg?: string, ...args: any[]): void {
        this.logger.debug(cloneDeep(obj), cloneDeep(msg), ...cloneDeep(args));
    }

    public silent(msg: string, ...args: any[]): void;
    public silent(obj: unknown, msg?: string, ...args: any[]): void;
    public silent<T extends object>(obj: T, msg?: string, ...args: any[]): void {
        this.logger.silent(cloneDeep(obj), cloneDeep(msg), ...cloneDeep(args));
    }

    public trace(msg: string, ...args: any[]): void;
    public trace(obj: unknown, msg?: string, ...args: any[]): void;
    public trace<T extends object>(obj: T, msg?: string, ...args: any[]): void {
        this.logger.trace(cloneDeep(obj), cloneDeep(msg), ...cloneDeep(args));
    }

    public debug(msg: string, ...args: any[]): void;
    public debug(obj: unknown, msg?: string, ...args: any[]): void;
    public debug<T extends object>(obj: T, msg?: string, ...args: any[]): void {
        this.logger.debug(cloneDeep(obj), cloneDeep(msg), ...cloneDeep(args));
    }

    public info(msg: string, ...args: any[]): void;
    public info(obj: unknown, msg?: string, ...args: any[]): void;
    public info<T extends object>(obj: T, msg?: string, ...args: any[]): void {
        this.logger.info(cloneDeep(obj), cloneDeep(msg), ...cloneDeep(args));
    }

    public warn(msg: string, ...args: any[]): void;
    public warn(obj: unknown, msg?: string, ...args: any[]): void;
    public warn<T extends object>(obj: T, msg?: string, ...args: any[]): void {
        this.logger.warn(cloneDeep(obj), cloneDeep(msg), ...cloneDeep(args));
    }

    public error(msg: string, ...args: any[]): void;
    public error(obj: unknown, msg?: string, ...args: any[]): void;
    public error<T extends object>(obj: T, msg?: string, ...args: any[]): void {
        this.logger.error(cloneDeep(obj), cloneDeep(msg), ...cloneDeep(args));
    }

    public fatal(msg: string, ...args: any[]): void;
    public fatal(obj: unknown, msg?: string, ...args: any[]): void;
    public fatal<T extends object>(obj: T, msg?: string, ...args: any[]): void {
        this.logger.fatal(cloneDeep(obj), cloneDeep(msg), ...cloneDeep(args));
    }
}

export default LoggingService;
